@import "./reset.css";

// variables
$bright-color: #ffffff;
$light-color: #eee;
$medium-color: #1f2526bf;
$medium-dark-color: #5f6566bf;
$dark-color: #222e2e;
$darker-color: #111717;

html,
body {
    margin: 0;
    padding: 0;
    border: none;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 16px;
    box-sizing: border-box;
}

html {
    background-color: $darker-color;
}

body {
    background-color: $medium-dark-color;
}

@mixin for-size($size) {
    @if $size == narrow-view {
        @media (max-width: 640px) {
            @content;
        }
    } @else if $size == less-than-full {
        @media (max-width: 1000px) {
            @content;
        }
    }
}

body > header {
    background-color: $dark-color;
    color: $light-color;
    padding: 1rem;
    letter-spacing: 1.75px;

    display: flex;
    align-items: baseline;
    gap: 1rem;

    a.site-title {
        color: inherit;
        text-decoration: none;
    }

    h1 {
        font-size: 18px;
        font-weight: 100;
        font-variant: small-caps;
    }

    nav {
        flex: 1;
        font-size: 12px;
        text-transform: uppercase;

        ul {
            display: flex;
            justify-content: flex-end;
            list-style-type: none;

            li {
                a {
                    color: $light-color;
                    display: inline-block;
                    padding: 0.5rem 1rem;
                    text-decoration: none;
                    white-space: nowrap;
                }
                &.current a {
                    transform: scale(1.1, 1.1);
                    color: $bright-color;
                }
                &:last-child {
                    background-color: rgba(163, 163, 163, 0.2);
                }
            }
        }
    }
    @include for-size(less-than-full) {
        & { //body > header
            flex-wrap: wrap;
            justify-content: center;

            nav {
                flex-basis: 100%;

                ul {
                    justify-content: center;

                    li a {
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                    }
                }
            }
        }
    }
    @include for-size(narrow-view) {
        & { //body > header
            nav ul {
                flex-wrap: wrap;
            }
        }
    }
}

footer {
    user-select: none;
    color: $light-color;
    background-color: $dark-color;

    nav {
        display:none;
        ul {
            list-style-type: none;
            li {
                padding: 1rem;
                display: inline-block;
                a {
                    color: $light-color;
                }
            }
            li.current a {
                text-decoration: none;
            }
        }
    }
}

hr {
    border: solid 1px $medium-color;
    margin: 3rem;
}

article {
    max-width: 1000px;
    margin: auto;
    padding: 2rem;
    background-color: white;
    box-shadow: $dark-color 0.2em 0.2em 0.4em;

    p {
        margin-block: 1rem;
    }

    a {
        color: currentColor;
    }

    li {
        margin-bottom: 1rem;
        margin-left: 2rem;
    }
}

figure.availability {
    line-height: 2rem;
    color: $dark-color;
    text-transform: uppercase;
}

figure.feature {
    display: grid;
    grid-template-columns: 1fr 1fr;
    img {
        grid-column-start: 1;
        width: 100%;
        cursor: zoom-in;
        transition: width 1s;
        &.expanded {
            width: 200%;
            cursor: zoom-out;
        }
    }
    figcaption {
        background-color: rgba($color: #fff, $alpha: 0.5);
        padding: 2rem;
        grid-column-start: 2;

        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        header {
            font-weight: bold;
            font-size: 2.5rem;
            font-family: Arial, Helvetica, sans-serif;
        }
        ul {
            list-style: none;
            li {
                color: $medium-color;
                margin-bottom: 1rem;
            }
        }
    }
    &:nth-child(even) {
        figcaption {
            grid-column-start: 1;
            grid-row: 1;
        }
        img {
            grid-column-start: 2;
        }
    }

    @include for-size(narrow-view) {
        & {
            display: block;

            figcaption header {
                font-size: 1.5rem;
            }
        }
    }
}

figure.feedback {
    display: inline-block;
    margin: 1rem 1%;
    vertical-align: top;

    blockquote {
        .dropcap {
            font-size: 5rem;
            color: lightgray;
            display: block;
            margin-bottom: -0.25rem;
            margin-top: 0.25rem;
        }

        font-style: italic;
        line-height: 2rem;
    }

    figcaption {
        margin-top: 1rem;
        text-align: right;
    }

    // lazy-quick mockup please improve
    $reduce-width: 90%;
    &.full {
        width: 1 * $reduce-width;
    }
    &.half {
        width: 0.5 * $reduce-width;
    }
    &.third {
        width: 0.33 * $reduce-width;
    }

    @include for-size(narrow-view) {
        &.half,
        &.third {
            width: 1 * $reduce-width;
        }
    }
}

aside {
    float: right;
    font-size: 1.75rem;
    padding: 1rem;
    width: 40%;

    @include for-size(less-than-full) {
        & {
            float: none;
            font-size: 1.2rem;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
            width: auto;
        }
    }
}

.bigButton {
    text-align: center;
    margin: 2rem 0;

    a {
        background-color: $dark-color;
        color: $light-color;
        display: inline-block;
        padding: 1rem;
        text-decoration: none;
    }
}
